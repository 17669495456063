export default defineComponent({
  props: {
    assessment: {
      type: Object,
      required: true
    },
    outcome: {
      type: Object,
      required: true
    }
  },
  setup: function setup() {},
  computed: {
    outcomeLink: function outcomeLink() {
      return "/".concat(this.assessment.id, "/impact/assessment/").concat(this.outcome.id);
    }
  }
});